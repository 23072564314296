import trustPilotLogo from '../../assets/img/trust-pilot.png';
import './style.css';


const Footer = () => {
  return <footer className='Footer'>
    <p><b>Great</b> 4 of 5</p>
    <img src={ trustPilotLogo } alt='trust-pilot-logo'/>
  </footer>
}

export default Footer;